/**
 * @file
 * Custom scripts for theme.
 */
(function ($) {

    $(document).ready( function() { //alert('kostas test');
        var nav = $('nav');
        var header = $('header');
        $('.nav-search').hide();
        $('.nav-logo').hide();


        $(window).scroll(function() {
            if (document.documentElement.clientWidth > 960) {
                if ($(document).scrollTop() > 227) {
                    header.css('marginTop', 100 ); // Prevent screen Jumping nav.height()
                    $('nav').addClass('animated slideInDown navbar-fixed-top stick-to-top').css('-vendor-animation-duration', '3.8s');
                    //$('.navbar-default').css( 'background', '#ffffff' );
                    //$('#header #main-menu').css({
                    //    'padding-top': '25px',
                    //    'padding-left': 0
                    //});
                    //$('#header #menu-search').css('padding-top', '46px');
                    //$('#header .dd-cart').css('margin-top', '0px');
                    $('.nav-logo').show();
                    $('.nav-search').show();
                    $('.free-shipping').hide();

                }
                if ($(document).scrollTop() < 127) {
                    header.css('marginTop', 0); // Prevent screen Jumping
                    $('nav').removeClass('animated slideInDown navbar-fixed-top stick-to-top').css('-vendor-animation-duration', '3.8s');
                    $('.navbar-default').css( 'background', 'transparent' );
/*                    $('#header #main-menu').css({
                        'padding-top': '0px',
                        'padding-left': '275px'
                    });*/
/*                    $('#header #menu-search').css('padding-top', '0');
                    $('#header .dd-cart').css('margin-top', '0');*/
                    $('.nav-logo').hide();
                    $('.nav-search').hide();

                    $('.free-shipping').show();
                }
            }
        });

        // Collapse SideBar
        $('#field_type_ref .pane-title').addClass('collapsed ');
        $('#field_type_ref .pane-title').attr({'data-toggle': 'collapse', 'data-target': '#target_field_type_ref'});
        $('#field_type_ref .pane-content').attr('id', 'target_field_type_ref').addClass('collapse ');

        //Pane 98 Brands
        $('#field_color_ref .pane-title').addClass('collapsed ');
        $('#field_color_ref .pane-title').attr({'data-toggle': 'collapse', 'data-target': '#target_field_color_ref'});
        $('#field_color_ref .pane-content').attr('id', 'target_field_color_ref').addClass('collapse ');

        //Pane 99 Brands
        $('#field_brand_ref .pane-title').addClass('collapsed ');
        $('#field_brand_ref .pane-title').attr({'data-toggle': 'collapse', 'data-target': '#target_field_brand_ref'});
        $('#field_brand_ref .pane-content').attr('id', 'target_field_brand_ref').addClass('collapse ');

        //Pane 102 Brands
        $('#field_collection_ref .pane-title').addClass('collapsed ');
        $('#field_collection_ref .pane-title').attr({'data-toggle': 'collapse', 'data-target': '#target_field_collection_ref'});
        $('#field_collection_ref .pane-content').attr('id', 'target_field_collection_ref').addClass('collapse ');

        //Pane 107 Search
        $('#field_size_ref .pane-title').addClass('collapsed ');
        $('#field_size_ref .pane-title').attr({'data-toggle': 'collapse', 'data-target': '#target_field_size_ref'});
        $('#field_size_ref .pane-content').attr('id', 'target_field_size_ref').addClass('collapse ');

        //Pane 86 Search
        $('#field_heel_size_ref .pane-title').addClass('collapsed ');
        $('#field_heel_size_ref .pane-title').attr({'data-toggle': 'collapse', 'data-target': '#target_field_heel_size_ref'});
        $('#field_heel_size_ref .pane-content').attr('id', 'target_field_heel_size_ref').addClass('collapse ');

        //Pane 111 Search
        $('#field_gender_ref .pane-title').addClass('collapsed ');
        $('#field_gender_ref .pane-title').attr({'data-toggle': 'collapse', 'data-target': '#target_field_gender_ref'});
        $('#field_gender_ref .pane-content').attr('id', 'target_field_gender_ref').addClass('collapse ');


        $('#content_type .pane-title').addClass('collapsed ');
        $('#content_type .pane-title').attr({'data-toggle': 'collapse', 'data-target': '#target_content_type'});
        $('#content_type .pane-content').attr('id', 'target_content_type').addClass('collapse ');
        
        
        // Store our function as a property of Drupal.behaviors.
        var onStartIsChecked = $( '.form-radio.ajax-processed:checked' ).attr("id") ;
            $( '#' + onStartIsChecked ).parent().css({
                "background": "#fff",
                "color": "#333"
            });
        Drupal.behaviors.eshop = {
            attach: function (context, settings) {
                var isChecked = $( '.form-radio.ajax-processed:checked' ).attr("id") ;
                $( '#' + isChecked ).parent().css({
                    "background": "#fff",
                    "color": "#333"
                });
            }
        };

    });
})(jQuery);